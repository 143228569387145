import React, { useEffect, useState } from 'react';

import { useItemDetail } from '@apis/items/useItemDetail';
import { ReactComponent as CartIcon } from '@assets/image/svg/cart_white.svg';
import { Button } from '@components/controls/button';
import { Block } from '@components/layout/block';
import { VStack } from '@components/layout/vstack';
import { Heading } from '@components/typographies/heading';
import { Paragraph } from '@components/typographies/paragraph';
import { Span } from '@components/typographies/span';
import { ItemTags } from '@features/items/detail/ItemTags';
import { ShopInfo } from '@features/items/detail/ShopInfo';
import { useDetectMedia } from '@hooks/useDetectMedia';
import { handleClick } from '@lib/handlers';
import { ListResponse } from '@pb/api/v1/user/item/item_pb';
import { useCartParams } from '@redux/slices/cartParamSlice';

type Props = {
  item: ListResponse.Item.AsObject;
};

export const ItemPopup = ({ item }: Props) => {
  const { addCartParam } = useCartParams();
  const [isItemAdded, setItemAdded] = useState<boolean>(false);
  const { md } = useDetectMedia();

  const { item: itemDetail } = useItemDetail(item.itemid);

  useEffect(() => {
    if (isItemAdded) {
      const timeout = setTimeout(() => {
        setItemAdded(false);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isItemAdded]);

  return (
    <Block width="100%">
      <VStack spacing="10px" align="flex-start">
        <Heading fontSize="16px" lh="24px" as="h2" mr="50px">
          {item?.itemName}
        </Heading>
        <Block maxHeight="455px" overflowY="auto" width="100%">
          {item && <ShopInfo item={item} md={md} />}
          <Paragraph>
            <Span fontWeight="bold" lineHeight="30px" fontSize="20px">
              {`¥${String(item?.price.toLocaleString())}`}
            </Span>
            (税込)
          </Paragraph>
          <Block paddingBottom={md ? '19px' : '5px'}>
            {isItemAdded ? (
              <Block
                display="flex"
                alignItems="center"
                width="198px"
                height="47px"
                padding="10px 16px"
                bg="skin"
                borderRadius="10px"
                border="2px solid var(--color-orange)"
                onClick={() => addCartParam(itemDetail!.itemid, 1)}
              >
                <Paragraph align="center" color="orange" fontSize="14px" fontWeight="700">
                  カートに追加されました
                </Paragraph>
              </Block>
            ) : (
              <Button
                width="198px"
                height="47px"
                radius="10px"
                color="white"
                padding="10px 16px"
                onClick={handleClick(() => {
                  addCartParam(itemDetail!.itemid, 1);
                  setItemAdded(true);
                })}
              >
                <CartIcon style={{ marginRight: '15px' }} />
                &nbsp;
                <Paragraph
                  align="center"
                  color="white"
                  fontSize="16px"
                  fontWeight="700"
                  width="fit-content"
                >
                  カートに追加する
                </Paragraph>
              </Button>
            )}
          </Block>
          {item && (
            <Block paddingBottom={md ? '5px' : '19px'} width="100%">
              <ItemTags item={item} direction="column" />
            </Block>
          )}
        </Block>
      </VStack>
    </Block>
  );
};
