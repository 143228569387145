import { useFloating, offset, shift } from '@floating-ui/react';
import React, { Suspense, useEffect } from 'react';
import styled from 'styled-components';

import { ItemPopup } from '../ItemPopup';

import { ReactComponent as Dots } from '@assets/image/svg/dots.svg';
import { CircleButton } from '@components/button/circle';
import { Drawer } from '@components/overlay/drawer';
import { Modal } from '@components/overlay/modal';
import { useDetectMedia } from '@hooks/useDetectMedia';
import { ListResponse } from '@pb/api/v1/user/item/item_pb';
import { usePopup } from '@redux/slices/popupSlice';

type Props = {
  item: ListResponse.Item.AsObject;
};

export const PopupButton = ({ item }: Props) => {
  const { openPopupItemId, setOpenId, close } = usePopup();
  const isOpen = openPopupItemId === item.itemid;

  const { md } = useDetectMedia();
  const { refs, floatingStyles, update } = useFloating({
    middleware: [offset(10), shift()],
  });

  useEffect(() => {
    if (isOpen) {
      const interval = setInterval(update);

      return () => {
        clearInterval(interval);
      };
    }
  }, [isOpen]);

  return (
    <>
      <CircleButton
        ref={refs.setReference}
        onClick={(e) => {
          e.stopPropagation();
          setOpenId(item.itemid);
          const handler = (e: MouseEvent) => {
            if (
              refs.floating.current &&
              e.target &&
              !refs.floating.current.contains(e.target as Node)
            ) {
              close();
            }
          };

          document.addEventListener('click', handler);

          return () => {
            document.removeEventListener('click', handler);
          };
        }}
        type="button"
        size="30px"
      >
        <Dots />
      </CircleButton>
      {md ? (
        isOpen && (
          <PopupModal style={floatingStyles} ref={refs.setFloating} position="absolute">
            <PopupContent>
              <Modal.CloseButton
                onClick={() => {
                  close();
                }}
              />
              <Suspense fallback={<p>Loading...</p>}>
                <ItemPopup item={item} />
              </Suspense>
            </PopupContent>
          </PopupModal>
        )
      ) : (
        <Drawer
          height={382}
          isOpen={isOpen}
          onClose={() => {
            close();
          }}
        >
          {isOpen && (
            <Suspense fallback={<p>Loading...</p>}>
              <ItemPopup item={item} />
            </Suspense>
          )}
        </Drawer>
      )}
    </>
  );
};

const PopupModal = styled(Modal)`
  overflow: hidden;
  width: 380px;
  border-radius: 15px;
`;

const PopupContent = styled(Modal.ModalContent)`
  width: 100%;
  padding: 15px;
`;
