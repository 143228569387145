import { useSpring, config, a } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import React, { useLayoutEffect } from 'react';
import styled from 'styled-components';

import { Overlay } from '../overlay';

import { Portal } from '@components/layout/portal';

type Props = {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  /**
   * height of drawer in px
   */
  height: number;
};

export const Drawer = ({ children, isOpen, onClose, height }: Props) => {
  const [{ y }, spring] = useSpring(() => ({ y: height }));

  const bind = useDrag(
    ({ last, velocity: [, vy], direction: [, dy], offset: [, oy], cancel }) => {
      if (oy < -70) cancel();

      if (last) {
        oy > height * 0.5 || (vy > 0.5 && dy > 0)
          ? onClose()
          : spring.start({ y: 0, immediate: false });
      } else spring.start({ y: oy, immediate: true });
    },
    { from: () => [0, y.get()], filterTaps: true, bounds: { top: 0 }, rubberband: true }
  );

  useLayoutEffect(() => {
    if (isOpen) {
      spring.start({ y: 0, immediate: false, config: config.stiff });
    }
  }, [isOpen]);

  return (
    <Portal>
      {isOpen && <Overlay />}
      <StyledDrawer
        {...bind()}
        style={{
          height: isOpen ? height : 0,
          transition: 'height 0.3s',
          y,
        }}
      >
        <SwipeBar />
        {children}
      </StyledDrawer>
    </Portal>
  );
};

const StyledDrawer = styled(a.div)`
  touch-action: none;
  position: fixed;
  border-radius: 30px 30px 0px 0px;
  bottom: 0;
  width: 100vw;
  z-index: var(--z-index-modal);
  background: white;
  left: 0;
  overflow-y: auto;
  padding-inline: 20px;
  padding-bottom: 20px;
`;

const SwipeBar = styled.div`
  width: 25%;
  height: 6px;
  border-radius: 3px;
  background: var(--color-gray-700);
  margin: 10px auto;
`;
