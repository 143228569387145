import React, { FC, FunctionComponent, PropsWithChildren } from 'react';
import styled, { CSSObject, StyledComponent } from 'styled-components';

import { CloseButton } from '../../assets/image/svg/CloseButton';

import { Card } from '@components/layout/card';
import { handleClick } from '@lib/handlers';

type ModalComponentType = StyledComponent<'div', never, Props, never> & {
  Overlay: StyledComponent<'div', never, object, never>;
  ModalContent: StyledComponent<'div', never, { width?: CSSObject['width'] }, never>;
  CloseButton: FC<{ onClick: () => void }>;
};

type Props = {
  position?: CSSObject['position'];
  left?: CSSObject['left'];
  top?: CSSObject['top'];
  md?: CSSObject;
};

export const Modal = styled.div<Props>((_) => ({
  position: _.position ?? 'fixed',
  top: _.top ?? '50%',
  left: _.left ?? '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 'var(--z-index-modal)',
  maxHeight: '80%',
  overflow: 'auto',
  '::-webkit-scrollbar': {
    display: 'inline',
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: '#ccc',
    borderRadius: '8px',
    border: '4px solid transparent',
    backgroundClip: 'padding-box',
  },
  '@media (min-width: 630px)': _.md,
})) as ModalComponentType;

Modal.Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: var(--z-index-overlay);

  display: flex;
  align-items: center;
  justify-content: center;
`;

Modal.ModalContent = styled.div<{ width?: CSSObject['width'] }>(({ width }) => ({
  background: '#fff',
  borderRadius: '16px',
  padding: '20px',
  position: 'relative',
  width: width || 'max-content',
  maxWidth: 'min(calc(100vw - 30px), 800px)',
}));

const Button = styled.button`
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: var(--z-index-modal-close-btn);
`;

// eslint-disable-next-line react/display-name
Modal.CloseButton = ({ onClick }: { onClick: () => void }) => (
  <Button onClick={handleClick(onClick)} type="button">
    <CloseButton />
  </Button>
);

export const ModalTemplate: FunctionComponent<PropsWithChildren<{ onClose: () => void }>> = ({
  children,
  onClose,
}) => {
  return (
    <Modal.Overlay onClick={onClose}>
      <Modal>
        <Modal.ModalContent
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Modal.CloseButton onClick={onClose} />
          <Card bg="white" mx="20px" my="20px">
            {children}
          </Card>
        </Modal.ModalContent>
      </Modal>
    </Modal.Overlay>
  );
};
