import styled from 'styled-components';

import { Button } from '@components/controls/button';
import { breakpoint } from '@constants/theme';

export const SearchButton = styled(Button)`
  border-radius: 10px;
  width: 100%;
  color: white;

  @media (min-width: ${breakpoint.md}px) {
    width: 100px;
  }
`;
