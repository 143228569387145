import React, { FC, useState } from 'react';

import { useAllergyList } from '@apis/allergy/useAllergyList';
import { Button } from '@components/controls/button';
import { Bar } from '@components/layout/bar';
import { Block } from '@components/layout/block';
import { Flex } from '@components/layout/flexbox';
import { VStack } from '@components/layout/vstack';
import { Paragraph } from '@components/typographies/paragraph';
import { useListRequest } from '@features/items/SearchList/hooks/useListRequest';
import { useAppDispatch } from '@redux/hooks';
import { close } from '@redux/slices/modalSlice';

export const AllergyForm: FC = () => {
  const dispatch = useAppDispatch();
  const { listRequest, updateAllergyList } = useListRequest();
  const { allergyList } = listRequest;
  const {
    data: { specificAllergiesList, allergiesList: allergiesSubList },
  } = useAllergyList();
  const [allergySelections, setAllergySelections] = useState<Set<string>>(new Set(allergyList));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    const newAllergySelections = new Set([...allergySelections]);

    if (checked) {
      newAllergySelections.add(value);
    } else {
      newAllergySelections.delete(value);
    }
    setAllergySelections(newAllergySelections);
  };

  const handleSave = () => {
    updateAllergyList(Array.from(allergySelections));
    dispatch(close());
  };

  const handleClear = () => {
    setAllergySelections(new Set());
    updateAllergyList([]);
  };

  return (
    <VStack spacing="10px">
      <Block width="100%">
        <Paragraph fontWeight="600" lh="20px">
          特定原材料8品目
        </Paragraph>
        <Paragraph fontWeight="600" lh="20px" color="deep-red">
          ※選択したアレルギー成分を除外した商品を表示します。
        </Paragraph>
      </Block>
      <Flex flexWrap="wrap" jc="flex-start" md={{ width: '100%', maxWidth: '500px' }}>
        {specificAllergiesList.map((item) => (
          <Flex key={item} width="80px" jc="space-between">
            <input
              key={item}
              id={item}
              name="allergy7"
              type="checkbox"
              style={{ appearance: 'auto', margin: '8px' }}
              value={item}
              onChange={handleChange}
              checked={allergySelections.has(item)}
            />
            <Paragraph wb="keep-all" as="label" htmlFor={item} fontWeight="600">
              {item}
            </Paragraph>
          </Flex>
        ))}
      </Flex>
      <Bar style={{ marginBottom: 0 }} />
      <Paragraph fontWeight="600" lh="30px">
        特定原材料に準ずる20品目
      </Paragraph>
      <Flex flexWrap="wrap" md={{ justifyContent: 'flex-start', maxWidth: '500px' }}>
        {allergiesSubList.map((item) => (
          <Flex key={item} style={{ marginRight: '10px' }} jc="space-between">
            <input
              name="allergy20"
              type="checkbox"
              style={{ appearance: 'auto', margin: '8px' }}
              value={item}
              onChange={handleChange}
              checked={allergySelections.has(item)}
            />
            <Paragraph wb="keep-all" as="label" fontWeight="600">
              {item}
            </Paragraph>
          </Flex>
        ))}
      </Flex>
      <Bar />
      <Flex width="200px" margin="0 0 0 auto">
        <Button width="90px" bg="white" border="1px gray solid" onClick={handleClear}>
          クリア
        </Button>
        <Button width="90px" bg="orange" color="white" onClick={handleSave}>
          保存
        </Button>
      </Flex>
    </VStack>
  );
};
