import React, { FC } from 'react';

export const CloseButton: FC = () => (
  <svg width="20" height="20" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.5 13C10.0899 13 13 10.0899 13 6.5C13 2.91015 10.0899 0 6.5 0C2.91015 0 0 2.91015 0 6.5C0 10.0899 2.91015 13 6.5 13Z"
      fill="#EBEBEB"
    />
    <path d="M3.57959 3.57905L8.73723 8.73669" stroke="#707070" />
    <path d="M8.73682 3.57905L3.57918 8.73669" stroke="#707070" />
  </svg>
);
