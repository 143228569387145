import { Script } from 'gatsby';
import React, { FC, Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';

import { Layout } from '@components/layout';
import { Description, title } from '@components/layout/SEO';
import { Loading } from '@components/loading';
import { MainVisual } from '@components/top/MainVisual';
import Pinterest from '@components/top/Pinterest';
import SearchList from '@components/top/SearchList';
import { breakpoint } from '@constants/theme';

const Top: FC = () => {
  return (
    <Layout bg="gray4">
      <Helmet>
        <title>{title} | あなたの「美味しい」に気づく、見つかる。Find your Tasty</title>
        {Description}
      </Helmet>
      <Script async src="https://www.googletagmanager.com/gtag/js?id=G-0J3S6WBQTW"></Script>
      <Script>{`
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-0J3S6WBQTW');
`}</Script>
      <TopContainer>
        <SearchList />
        <MainVisual />
        <PinterestContainer>
          <Suspense fallback={<Loading />}>
            <Pinterest />
          </Suspense>
        </PinterestContainer>
      </TopContainer>
    </Layout>
  );
};

export default Top;

const TopContainer = styled.div`
  padding-bottom: 100px;
`;

const PinterestContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: center;

  @media screen and (min-width: ${breakpoint.md}px) {
    margin-top: 40px;
  }
`;
