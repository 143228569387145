import React, { FC, useState } from 'react';

import { useAppDispatch } from '../../../redux/hooks';

import { Button } from '@components/controls/button';
import { StyledSelect } from '@components/controls/select';
import { Bar } from '@components/layout/bar';
import { Block } from '@components/layout/block';
import { Flex } from '@components/layout/flexbox';
import { HStack } from '@components/layout/hstack';
import { Paragraph } from '@components/typographies/paragraph';
import { useListRequest } from '@features/items/SearchList/hooks/useListRequest';
import { initialState } from '@redux/slices/listRequestSlice';
import { close } from '@redux/slices/modalSlice';

export const PriceForm: FC = () => {
  const dispatch = useAppDispatch();
  const { listRequest, updateMinPrice, updateMaxPrice } = useListRequest();

  const { price } = listRequest;

  const [tempMinPrice, setTempMinPrice] = useState(price?.first || 0);
  const [tempMaxPrice, setTempMaxPrice] = useState(price?.last || 0);

  // devide action by clicking save button and clicking clear button
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const submitEvent = new SubmitEvent('submit', e.nativeEvent);
    const { id } = submitEvent.submitter as HTMLEmbedElement;
    switch (id) {
      case 'save':
        updateMinPrice(tempMinPrice);
        updateMaxPrice(tempMaxPrice);
        dispatch(close());
        break;
      case 'clear':
        setTempMinPrice(initialState.price?.first ?? 0);
        setTempMaxPrice(initialState.price?.last ?? 0);
        updateMinPrice(initialState.price?.first ?? 0);
        updateMaxPrice(initialState.price?.last ?? 0);
        break;
      default:
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <HStack margin="auto" spacing="20px" width="max-content">
        <Flex direction="column">
          <Paragraph fontWeight="600">最低価格</Paragraph>
          <Block marginTop="4px">
            <StyledSelect
              height="36px"
              width="94px"
              onChange={(e) => setTempMinPrice(Number(e.target.value))}
              value={tempMinPrice}
            >
              <option value={0}>0</option>
              <option value={500}>500</option>
              <option value={1000}>1,000</option>
              <option value={2000}>2,000</option>
              <option value={3000}>3,000</option>
              <option value={4000}>4,000</option>
              <option value={5000}>5,000</option>
              <option value={10000}>10,000</option>
            </StyledSelect>
          </Block>
        </Flex>
        <span style={{ paddingTop: '18px' }}>〜</span>
        <Flex direction="column">
          <Paragraph fontWeight="600">最高価格</Paragraph>
          <Block marginTop="4px">
            <StyledSelect
              height="36px"
              width="94px"
              onChange={(e) => setTempMaxPrice(Number(e.target.value))}
              value={tempMaxPrice}
            >
              <option value={initialState.price?.last}>未選択</option>
              <option value={0}>0</option>
              <option value={500}>500</option>
              <option value={1000}>1,000</option>
              <option value={2000}>2,000</option>
              <option value={3000}>3,000</option>
              <option value={4000}>4,000</option>
              <option value={5000}>5,000</option>
              <option value={10000}>10,000</option>
            </StyledSelect>
          </Block>
        </Flex>
      </HStack>
      <Bar />
      <Flex width="200px" margin="0 auto">
        <Button width="90px" bg="white" border="1px gray solid" id="clear">
          クリア
        </Button>
        <Button width="90px" bg="orange" color="white" type="submit" id="save">
          保存
        </Button>
      </Flex>
    </form>
  );
};
