import React, { FC, useState } from 'react';

import { Button } from '@components/controls/button';
import { Bar } from '@components/layout/bar';
import { Block } from '@components/layout/block';
import { Flex } from '@components/layout/flexbox';
import { Paragraph } from '@components/typographies/paragraph';
import { useListRequest } from '@features/items/SearchList/hooks/useListRequest';
import { useAppDispatch } from '@redux/hooks';
import { close } from '@redux/slices/modalSlice';

const particulars = [
  '減塩・無塩',
  '低脂肪・無脂肪',
  'グルテンフリー',
  'ローカーボ・低糖質',
  'ヴィーガン',
  'オリエンタルヴィーガン',
  'ラクトベジタリアン',
  'オボベジタリアン',
  'ラクトオボベジタリアン',
  'ハラール',
  'コーシャ',
  '保存料・防腐剤不使用',
  '着色料不使用',
  '化学調味料不使用',
  '香料不使用',
  '人工甘味料不使用',
  '機能性食品',
  '高たんぱく',
];
export const CommitForm: FC = () => {
  const { listRequest, updateParticularList } = useListRequest();
  const dispatch = useAppDispatch();
  const { particularList } = listRequest;

  const [selectedParticulars, setSelectedParticulars] = useState<Set<string>>(
    new Set(particularList)
  );

  const handleParticularChange = (particular: string, checked: boolean) => {
    const newSelectedParticulars = new Set(selectedParticulars);
    if (checked) {
      newSelectedParticulars.add(particular);
    } else {
      newSelectedParticulars.delete(particular);
    }
    setSelectedParticulars(newSelectedParticulars);
  };

  const clearSelections = () => {
    setSelectedParticulars(new Set());
    updateParticularList([]);
  };

  const saveSelections = () => {
    updateParticularList(Array.from(selectedParticulars));
    dispatch(close());
  };

  return (
    <Block margin="20px 0 0 0">
      {/* TODO: 初期リリースでは実装なし */}
      {/* <Flex md={{ justifyContent: 'flex-start' }}>
              <Paragraph fontWeight="600" width="60px" md={{ width: '120px' }}>
                産地
              </Paragraph>
              <Flex width="180px" jc="space-between">
                <StyledSelect height="30px" width="80px">
                  <option value="0">都道府県</option>
                  <option value="500">500</option>
                  <option value="500">1000</option>
                </StyledSelect>
                <StyledSelect height="30px" width="80px">
                  <option value="0">市町村</option>
                  <option value="500">500</option>
                  <option value="500">1000</option>
                </StyledSelect>
              </Flex>
            </Flex>
            <Bar /> */}
      <Paragraph fontWeight="600" width="80px" md={{ width: '120px' }}>
        こだわり
      </Paragraph>
      <Flex style={{ overflow: 'auto', padding: '15px 0' }} flexWrap="wrap" md={{ width: '600px' }}>
        {particulars.map((particular) => (
          <Flex width="180px" md={{ width: '200px' }} key={particular}>
            <input
              type="checkbox"
              style={{ appearance: 'auto', margin: '8px' }}
              checked={selectedParticulars.has(particular)}
              onChange={(e) => handleParticularChange(particular, e.target.checked)}
            />
            <Paragraph fontWeight="600">{particular}</Paragraph>
          </Flex>
        ))}
      </Flex>
      {/* <CommitModalStyle.Bar />
            <Flex md={{ justifyContent: 'flex-start' }}>
              <Paragraph fontWeight="600" width="80px" md={{ width: '120px' }}>
                限定表示
              </Paragraph>
              <Flex width="180px" flexWrap="wrap" md={{ width: '400px' }}>
                <Flex>
                  <input type="checkbox" style={{ appearance: 'auto', margin: '8px' }} />
                  <Paragraph fontWeight="600">在庫切れ商品も表示</Paragraph>
                </Flex>
                <Flex>
                  <input type="checkbox" style={{ appearance: 'auto', margin: '8px' }} />
                  <Paragraph fontWeight="600">Tastyからの商品発送のみ表示</Paragraph>
                </Flex>
              </Flex>
            </Flex> */}
      <Bar />
      <Flex width="200px" margin="auto" md={{ margin: '0 0 0 auto' }}>
        <Button width="90px" bg="white" border="1px gray solid" onClick={clearSelections}>
          クリア
        </Button>
        <Button width="90px" bg="orange" color="white" onClick={saveSelections}>
          保存
        </Button>
      </Flex>
    </Block>
  );
};
