import React, { ReactNode } from 'react';

import { Button } from './button';

import { handleClick } from '@lib/handlers';

type Props = {
  value?: string;
  checked: boolean;
  onChange: (value?: string) => void;
  children: ReactNode;
};

export const Radio = ({ value, checked, onChange, children }: Props) => {
  return checked ? (
    <Button
      bg="orange"
      color="white"
      height="25px"
      onClick={handleClick(() => {
        onChange();
      })}
    >
      {children}
    </Button>
  ) : (
    <Button
      bg="white"
      height="25px"
      color="gray"
      onClick={handleClick(() => {
        onChange(value);
      })}
      border="1px solid var(--color-border-gray)"
    >
      {children}
    </Button>
  );
};
