import styled from 'styled-components';

import { ReactComponent as SearchIcon } from '@assets/image/svg/search_white.svg';
import { Input } from '@components/controls/Input';
import { breakpoint } from '@constants/theme';

export const SearchInput = styled(Input)`
  padding-inline: 13px;
  width: 100%;
  height: 34px;
  border-radius: 10px;

  @media (min-width: ${breakpoint.md}px) {
    width: 230px;
  }
`;

export const FloatingSearchIcon = styled(SearchIcon)`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`;
