import React, { FC, useState } from 'react';

import { useAppDispatch } from '../../../redux/hooks';
import { close } from '../../../redux/slices/modalSlice';

import { Button } from '@components/controls/button';
import { Radio } from '@components/controls/radio';
import { Bar } from '@components/layout/bar';
import { Flex } from '@components/layout/flexbox';
import { HStack } from '@components/layout/hstack';
import { Paragraph } from '@components/typographies/paragraph';
import { useListRequest } from '@features/items/SearchList/hooks/useListRequest';

const tastes = ['甘い', '塩辛い', '辛い', '旨い', '酸っぱい', '苦い'];

export const TasteForm: FC = () => {
  const { updateTasteList, listRequest } = useListRequest();
  const dispatch = useAppDispatch();

  const [selectedTastes, setSelectedTastes] = useState<string | undefined>(
    listRequest.tasteList[0]
  );

  const clearSelections = () => {
    setSelectedTastes(undefined);
    updateTasteList([]);
  };

  const saveSelections = () => {
    updateTasteList(selectedTastes ? [selectedTastes] : []);
    dispatch(close());
  };

  return (
    <>
      <Paragraph fontWeight="600" lh="40px">
        お好きな味覚を選択
      </Paragraph>
      <HStack wrap spacing="20px">
        {tastes.map((taste) => (
          <Flex key={taste} height="36px">
            <Radio value={taste} checked={selectedTastes === taste} onChange={setSelectedTastes}>
              {taste}
            </Radio>
          </Flex>
        ))}
      </HStack>
      <Bar />
      <Flex width="200px" margin="0 auto" md={{ margin: '0 0 0 auto' }}>
        <Button width="90px" bg="white" border="1px gray solid" onClick={clearSelections}>
          クリア
        </Button>
        <Button width="90px" bg="orange" color="white" onClick={saveSelections}>
          保存
        </Button>
      </Flex>
    </>
  );
};
