import styled from 'styled-components';

import { breakpoint } from '@constants/theme';

export const SearchCard = styled.div`
  display: flex;
  border-radius: 20px;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-inline: 15px;
  width: 100%;

  @media (min-width: ${breakpoint.md}px) {
    width: 860px;
    padding-top: 15px;
    padding-bottom: 15px;
  }
`;
