import { axiosClient } from '@api/axiosClient';
import { useSuspenseFetch } from '@hooks/useSuspenseFetch';
import { ListRequest, ListResponse } from '@pb/api/v1/user/allergy/allergy_pb';

const endpoint = `/api/v1/user/allergy/list`;

export const useAllergyList = () => {
  return useSuspenseFetch(
    endpoint,
    async () => {
      const listRequest = new ListRequest();
      const res = await axiosClient.post(endpoint, listRequest.serializeBinary());

      return ListResponse.deserializeBinary(res.data).toObject();
    },
    { suspense: true }
  );
};
