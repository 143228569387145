import { Link } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';

import { PopupButton } from './PopupButton';

import { NoImage } from '@assets/image/png';
import { Grid } from '@components/layout/grid';
import { VStack } from '@components/layout/vstack';
import { Heading } from '@components/typographies/heading';
import { Paragraph } from '@components/typographies/paragraph';
import { ListResponse } from '@pb/api/v1/user/item/item_pb';

type Props = {
  item: ListResponse.Item.AsObject;
};

const ItemContent: FC<Props> = ({ item }) => {
  return (
    <VStack width="100%" spacing="8px" align="flex-start">
      <Link style={{ width: '100%' }} to={`/items/${item.itemid}`}>
        <Img src={item.imagePath ? item.imagePath : (NoImage as string)} />
      </Link>
      <Content>
        <VStack spacing="5px">
          <ItemName as="h6">{item.itemName}</ItemName>
          <Paragraph>{`${String(item.price.toLocaleString())}円`}</Paragraph>
        </VStack>
        <PopupButton item={item} />
      </Content>
    </VStack>
  );
};

export default ItemContent;

const Img = styled.img`
  width: 100%;
  border-radius: 20px;
  background: white;
`;

const ItemName = styled(Heading)`
  font-size: 16px;
  word-break: break-all;
  width: 100%;
  overflow: hidden;
`;

const Content = styled(Grid)`
  grid-template-columns: 1fr 35px;
  width: 100%;
  gapL 15px;
`;
