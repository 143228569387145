import React from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import { MVbgPc, MVbgSp } from '@assets/image/webp';
import { breakpoint } from '@constants/theme';
import { useDetectMedia } from '@hooks/useDetectMedia';

type ImageLink = {
  src: string;
  link: string;
};

const PC_IMAGES: ImageLink = { src: MVbgPc, link: '/lp/for-beginner' };
const SP_IMAGES: ImageLink = { src: MVbgSp, link: '/lp/for-beginner' };

export const MainVisual = () => {
  const { sm } = useDetectMedia();
  const IMAGES = sm ? PC_IMAGES : SP_IMAGES;

  return (
    <MainVisualContainer>
      <SlideImage src={IMAGES.src} onClick={() => navigate(IMAGES.link)} />
    </MainVisualContainer>
  );
};

const MainVisualContainer = styled.div`
  width: 100%;
  height: 214px;
  position: relative;
  display: flex;
  justify-content: center;

  @media screen and (min-width: ${breakpoint.sm}px) {
    height: 254px;
  }
`;

const SlideImage = styled.div<{ src: string; overlay?: boolean }>`
  position: relative;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  cursor: pointer;

  @media screen and (min-width: ${breakpoint.xxl}px) {
    width: ${breakpoint.xxl}px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  &:hover::before {
    background-color: ${(props) => !props.overlay && 'rgba(255, 255, 255, 0.4)'};
  }
`;
