import styled from 'styled-components';

type Props = {
  active?: boolean;
};

export const SearchFilterButton = styled.button<Props>`
  width: max-content;
  padding-left: 15px;
  padding-right: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 9999px;
  font-size: 14px;
  background-color: ${(_) => (_.active ? 'var(--color-bg-orange)' : 'transparent')};
  line-height: 25px;
  border: ${(_) => (_.active ? 'none' : '1px solid var(--color-border-gray)')};
  color: ${(_) => (_.active ? 'white' : 'var(--color-gray)')};

  path {
    stroke: ${(_) => (_.active ? 'white' : 'var(--color-gray)')};
  }

  > svg {
    margin-left: 5px;
  }
`;
