import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import ItemContent from '../ItemContent';

import { getItemList } from '@api/item';
import { Block } from '@components/layout/block';
import { Grid } from '@components/layout/grid';
import { VStack } from '@components/layout/vstack';
import { Paragraph } from '@components/typographies/paragraph';
import { breakpoint } from '@constants/theme';
import { useListRequest } from '@features/items/SearchList/hooks/useListRequest';
import { useSuspenseFetch } from '@hooks/useSuspenseFetch';
import { ErrorResponse } from '@pb/api/error_pb';
import { ListResponse } from '@pb/api/v1/user/item/item_pb';

const getCols = () => {
  if (typeof window === 'undefined') {
    return 0;
  }
  if (window.matchMedia('(min-width: 1300px)').matches) {
    return 7;
  }
  if (window.matchMedia('(min-width: 1100px)').matches) {
    return 5;
  }
  if (window.matchMedia('(min-width: 800px)').matches) {
    return 4;
  }
  if (window.matchMedia('(min-width: 600px)').matches) {
    return 3;
  }
  return 2;
};

const Pinterest: FC = () => {
  const { listRequest } = useListRequest();
  // FIXME: カスタムフックに切ってgetItemListをexportしない代わりにhooksでexportさせる
  const { data: itemList } = useSuspenseFetch<ListResponse.Item.AsObject[], ErrorResponse>(
    ['itemList', listRequest],
    () => getItemList(listRequest),
    {
      suspense: true,
      useErrorBoundary: true,
      refetchInterval: 1000 * 60,
      refetchOnWindowFocus: false,
    }
  );

  const [cols, setCols] = useState(getCols());
  const colItems = Array.from({ length: cols }, (_, i1) =>
    itemList.filter((_, i2) => i2 % cols === i1)
  );

  const resizeEvent = useCallback(
    () => window.addEventListener('resize', () => setCols(getCols())),
    []
  );

  useEffect(() => {
    resizeEvent();
    return () => window.removeEventListener('resize', resizeEvent);
  }, []);

  return (
    <PinterContainer>
      {itemList?.length === 0 && (
        <Block width="100%" textAlign="center" bg="gray4" padding="20px 0">
          <Paragraph>該当する商品は見つかりませんでした。</Paragraph>
        </Block>
      )}
      <PinterestGrid gap="14px" gridTemplateColumns={`repeat(${cols}, 1fr)`}>
        {colItems.map((items, i) => (
          <VStack spacing="16px" key={i} width="100%">
            {items.map((item) => (
              <ItemContent key={item.itemid} item={item} />
            ))}
          </VStack>
        ))}
      </PinterestGrid>
    </PinterContainer>
  );
};

export default Pinterest;

const PinterestGrid = styled(Grid)`
  width: calc(100% - 30px);

  @screen and media(min-width: ${breakpoint.md}px) {
    width: calc(100% - 46px);
  }
`;

const PinterContainer = styled.div`
  width: 100%;
  max-width: ${breakpoint.xxl}px;
  display: flex;
  justify-content: center;
`;
