import { AxiosError, AxiosResponse } from 'axios';

import { Pagination } from '../pb/api/v1/common/common_pb';
import {
  ListRequest,
  ListResponse,
  DetailRequest,
  DetailResponse,
} from '../pb/api/v1/user/item/item_pb';

import { axiosClient } from './axiosClient';

export const getItemList = async (listRequestObj: ListRequest.AsObject) => {
  const listRequest = new ListRequest();

  const pagination = new Pagination();
  pagination.setPage(listRequestObj.pagination?.page ?? 1);
  pagination.setPerPage(listRequestObj.pagination?.perPage ?? 999);
  listRequest.setPagination(pagination);

  if (listRequestObj.keyword && listRequestObj.keyword !== '') {
    listRequest.setKeyword(listRequestObj.keyword);
  }

  if (listRequestObj.price) {
    const price = new ListRequest.Price();
    price.setFirst(listRequestObj.price.first ?? 0);
    price.setLast(listRequestObj.price.last ?? 9999999);
    listRequest.setPrice(price);
  }

  if (listRequestObj.allergyList.length > 0) {
    listRequest.setAllergyList(listRequestObj.allergyList);
  }

  if (listRequestObj.particularList.length > 0) {
    listRequest.setParticularList(listRequestObj.particularList);
  }

  if (listRequestObj.tasteList.length > 0) {
    listRequest.setTasteList(listRequestObj.tasteList);
  }

  const itemList = await axiosClient
    .post('/api/v1/user/item/list', listRequest.serializeBinary())
    .then((res: AxiosResponse<Uint8Array>) => {
      const { data } = res;
      const listResponse = ListResponse.deserializeBinary(data);
      return listResponse.toObject().itemsList;
    });

  return itemList;
};

/**
 * @deprecated
 * see @apis/items/useItemDetail.ts
 */
export const getItemDetail = async (id: number) => {
  const detailRequest = new DetailRequest();
  detailRequest.setItemid(id);

  const itemDetail = await axiosClient
    .post('/api/v1/user/item/detail', detailRequest.serializeBinary())
    .then((res: AxiosResponse<Uint8Array>) => {
      const { data } = res;
      const itemDetail = DetailResponse.deserializeBinary(data);
      return itemDetail.toObject();
    })
    .catch((err: AxiosError) => {
      // TODO:この辺でエラーコードごとのエラーメッセージ設定をする
      throw new Error(err.message);
    });

  return itemDetail;
};
