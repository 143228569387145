import React, { Suspense, useEffect } from 'react';

import { AllergyForm } from './AllergyForm';
import { CommitForm } from './CommitForm';
import { PriceForm } from './PriceForm';
import { TasteForm } from './TasteForm';

import { Drawer } from '@components/overlay/drawer';
import { ModalTemplate } from '@components/overlay/modal';
import { useDetectMedia } from '@hooks/useDetectMedia';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { close } from '@redux/slices/modalSlice';

const DrawerHeight = {
  'top/taste': 240,
  'top/allergy': 540,
  'top/commit': 540,
  'top/price': 200,
} as const;

export const SearchModal = () => {
  const { key } = useAppSelector((state) => state.modalState);
  const dispatch = useAppDispatch();
  const { md } = useDetectMedia();

  const onClose = () => {
    dispatch(close());
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('beforeunload', () => {
        dispatch(close());
      });
      return () => {
        dispatch(close());
      };
    }
  }, []);

  if (md) {
    return key ? (
      <ModalTemplate onClose={onClose}>
        {key === 'top/taste' && <TasteForm />}
        {key === 'top/allergy' && <AllergyForm />}
        {key === 'top/commit' && <CommitForm />}
        {key === 'top/price' && <PriceForm />}
      </ModalTemplate>
    ) : null;
  }

  return (
    <Drawer isOpen={!!key} height={DrawerHeight[key ?? 'top/taste']} onClose={onClose}>
      <Suspense fallback={<div />}>
        {key === 'top/taste' && <TasteForm />}
        {key === 'top/allergy' && <AllergyForm />}
        {key === 'top/commit' && <CommitForm />}
        {key === 'top/price' && <PriceForm />}
      </Suspense>
    </Drawer>
  );
};
