import { ListRequest } from '@pb/api/v1/user/item/item_pb';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import {
  setListRequest,
  setKeyword,
  setMinPrice,
  setMaxPrice,
  setAllergyList,
  setParticularList,
  setTasteList,
} from '@redux/slices/listRequestSlice';

export const useListRequest = () => {
  const listRequest = useAppSelector((state) => state.listRequestReducer);
  const dispatch = useAppDispatch();

  const updateListRequest = (listRequest: ListRequest.AsObject) => {
    dispatch(setListRequest(listRequest));
  };

  const updateKeyword = (keyword: string) => {
    dispatch(setKeyword(keyword));
  };

  const updateMinPrice = (minPrice: number) => {
    dispatch(setMinPrice(minPrice));
  };

  const updateMaxPrice = (maxPrice: number) => {
    dispatch(setMaxPrice(maxPrice));
  };

  const updateAllergyList = (allergyList: string[]) => {
    dispatch(setAllergyList(allergyList));
  };

  const updateParticularList = (particularList: string[]) => {
    dispatch(setParticularList(particularList));
  };

  const updateTasteList = (tasteList: string[]) => {
    dispatch(setTasteList(tasteList));
  };

  return {
    listRequest,
    updateListRequest,
    updateKeyword,
    updateMinPrice,
    updateMaxPrice,
    updateAllergyList,
    updateParticularList,
    updateTasteList,
  };
};
