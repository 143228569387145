import styled from 'styled-components';

type Props = {
  size: '30px' | '47px';
  bg?: 'white' | 'gray';
};

export const CircleButton = styled.button<Props>((_) => ({
  background: `var(--color-bg-${_.bg ?? 'white'})`,
  height: _.size,
  width: _.size,
  borderRadius: '9999px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
}));
