import React, { FC } from 'react';

import { SearchButton } from './SearchButton';
import { SearchCard } from './SearchCard';
import { SearchFilterButton } from './SearchFilterButton';
import { FloatingSearchIcon, SearchInput } from './SearchInput';
import { SearchModal } from './SearchModals';

import { getItemList } from '@api/item';
import { ReactComponent as FilterIcon } from '@assets/image/svg/filter.svg';
import { Block } from '@components/layout/block';
import { Center } from '@components/layout/center';
import { HStack } from '@components/layout/hstack';
import { ResponsiveStack } from '@components/layout/responsive-stack';
import { useListRequest } from '@features/items/SearchList/hooks/useListRequest';
import { useAppDispatch } from '@redux/hooks';
import { initialState } from '@redux/slices/listRequestSlice';
import { setSearchItemList } from '@redux/slices/listResponseSlice';
import {
  openAllergyModal,
  openCommitModal,
  openPriceModal,
  openTasteModal,
} from '@redux/slices/modalSlice';

const SearchList: FC = () => {
  const dispatch = useAppDispatch();
  const { updateKeyword, listRequest } = useListRequest();

  const onClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const fetchedItemList = await getItemList(listRequest);
    dispatch(setSearchItemList(fetchedItemList));
  };

  return (
    <Center>
      <SearchCard>
        <ResponsiveStack justify="space-between" spacing="15px">
          <Block
            width="100%"
            md={{
              width: '240px',
            }}
            position="relative"
          >
            <SearchInput
              placeholder="キーワードで検索"
              bg="gray-second"
              value={listRequest.keyword}
              onChange={(e) => {
                updateKeyword(e.target.value);
              }}
            />
            <FloatingSearchIcon />
          </Block>
          <HStack rg="10px" justify="center" width="100%" spacing="13px" wrap>
            <SearchFilterButton
              active={!!listRequest.tasteList.length}
              onClick={() => dispatch(openTasteModal())}
            >
              味覚
              <FilterIcon />
            </SearchFilterButton>
            <SearchFilterButton
              active={!!listRequest.allergyList.length}
              onClick={() => dispatch(openAllergyModal())}
            >
              アレルギー
              <FilterIcon />
            </SearchFilterButton>
            <SearchFilterButton
              active={!!listRequest.particularList.length}
              onClick={() => dispatch(openCommitModal())}
            >
              こだわり
              <FilterIcon />
            </SearchFilterButton>
            <SearchFilterButton
              active={
                !!listRequest.price?.first || listRequest.price?.last !== initialState.price?.last
              }
              onClick={() => dispatch(openPriceModal())}
            >
              価格
              <FilterIcon />
            </SearchFilterButton>
          </HStack>
          <SearchButton onClick={onClick}>検索</SearchButton>
        </ResponsiveStack>
        <SearchModal />
      </SearchCard>
    </Center>
  );
};

export default SearchList;
